<template>
<div class="item5">
  <div class="title">
    <div>
      <p>{{info.title}}</p>
      <div class="line"></div>
    </div>
    <img :src="require('../../assets/home/' + info.img)">
  </div>
  <p class="describe text-left">{{info.describe}}</p>
</div>
</template>

<script>
export default {
  name: "item5",
  props: ['info']
}
</script>

<style scoped lang="scss">
.item5 {
  background: #FBFCFF;
  border: 1px solid #E4EAFF;
  border-radius: 4px 80px 4px 4px;
  box-sizing: border-box;
}

.title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  p {
    font-size: 18px;
    color: #2555FF;
  }

  img {
    width: 60px;
    height: 60px;
  }

  .line {
    border: 1px solid #325FFF;
    margin-top: 30px;
    width: 50%;
  }
}



.describe {
  color: #999A9C;
  font-size: 16px;
  line-height: 30px;
}

@media screen and ( min-width: 768px ) {
  .item5 {
    width: 277px;
    height: 322px;
    padding: 54px 30px 0 36px;
  }
}

@media screen and ( max-width: 768px) {
  .item5 {
    width: 45%;
    padding: 15px 15px 15px 10px;
    border-radius: 4px 50px 4px 4px;
  }

  .title {
    margin-bottom: 30px;

    img {
      width: 40px;
      height: 40px;
    }

    .line {
      margin-top: 15px;
    }
  }

  .describe {
    color: #999A9C;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>