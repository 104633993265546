<template>
<div class="item2">
  <p class="title">{{ info.title }}</p>
  <div class="line"></div>
  <div class="describe">
    <p class="text-left md:text-center">{{ info.describe }}</p>
    <div>
      <p v-for="(item,i) in info.items" :key="i">
        <span></span>{{item}}
      </p>
    </div>
  </div>
  <div class="imgBox">
    <img :src="require('../../assets/home/' + info.img)">
  </div>
</div>
</template>

<script>
export default {
  name: "item2",
  props: ['info']
}
</script>

<style scoped lang="scss">
.item2 {
  box-shadow: 0px 15px 25px 0px rgba(53, 100, 230, 0.15);
  border-radius: 30px;
  box-sizing: border-box;
  color: #333;

  padding: 0 27px;
  position: relative;
}
.title {
  font-weight: bold;
  font-size: 18px;
  margin: 45px auto 25px;
}
.line {
  border: 1px solid #096DD9;
  width: 10%;
  margin: 2vh auto;
}
.describe {
  font-size: 14px;
  line-height: 24px;

  > p {
    margin-bottom: 25px;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #666;

    p {
      width: 49%;
      text-align: left;
      line-height: 30px;
    }

    span {
      display: inline-block;
      background: #2555FF;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      margin-right: 10px;
    }
  }
}


@media screen and ( min-width: 768px ) {
  .item2 {
    width: 380px;
    height: 550px;
  }
  .imgBox {
    width: 340px;
    height: 200px;
    position: absolute;
    bottom: 30px;
    left: 20px;

    img {
      width: 100%;
    }
  }
}

@media screen and ( max-width: 768px ) {
  .item2 {
    width: 90%;
    padding: 0 20px;
  }

  .imgBox {
    width: 100%;
    margin: 45px 0;

    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .describe {
    div {
      p {
        line-height: 24px;
      }
    }
  }
}
</style>