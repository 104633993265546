<template>
  <div class="home">
    <banner :card-back="cardBack"/>
    <div class="item">
      <div class="fs16 mx-auto xl:w-1200 hidden xl:block">
        <p class="title">产品介绍</p>
        <div class="block xl:flex">
          <img style="width: 650px;height: 450px;"
               src="../assets/home/1-0.png">
          <div class="right text-center xl:text-left w-full xl:w-2/4">
            <p style="color: #333;margin: 87px 0;">
              数据可信为数据全生命周期保驾护航。
              对数据的来源、对象、权属、创建、变更、应用、传输、存储等环节提供可信服务保障
            </p>
            <p v-for="item in item1Info" :key="item"
               style="color: #666;width: 493px;">
              <span class="blueDot"></span>
              {{ item }}
            </p>
          </div>
        </div>
        <div class="flex justify-around mt-12 xl:mt-0">
          <div v-for="(item,i) in item1" :key="i">
            <img class="w-120px h-120px"
                 :src="require('../assets/home/' + item.img)">
            <p style="line-height: 18px;">{{ item.name }}</p>
          </div>
        </div>
      </div>

      <div class="fs16 mx-auto xl:w-1200 block xl:hidden">
        <p class="title">产品介绍</p>
        <p class="text-left md:text-center"
           style="font-weight: 400;color: #333;line-height: 24px;">
          数据可信为数据全生命周期保驾护航。
          对数据的来源、对象权属、创建、变更、应用、传输、存储等环节提供可信服务保障。
        </p>
        <div class="flex items-center">
          <img class="w-2/4 sm:w-3/5"
               src="../assets/home/1-0.png">
          <div style="line-height: 30px">
            <p v-for="item in item1Info"
               :key="item"
               style="color: #666;">
              <span class="blueDot"></span>
              {{ item }}
            </p>
          </div>
        </div>
        <div class="flex justify-around mt-12 xl:mt-0">
          <div v-for="(item,i) in item1" :key="i">
            <img class="w-80px md:w-100px h-80px md:h-100px"
                 :src="require('../assets/home/' + item.img)">
            <p style="line-height: 18px;">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">产品业务</p>
        <div class="flex justify-around flex-wrap xl:justify-between">
          <item2 class="mb-8 xl:mb-0 w-full"
                 v-for="(item,i) in item2"
                 :key="i" :info="item"></item2>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">核心技术</p>
        <div class="flex justify-around flex-wrap xl:justify-between">
          <item3 class="mb-8 xl:mb-0"
                 style=""
                 v-for="(item,i) in item3"
                 :key="i" :info="item"></item3>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">服务内容</p>
        <div class="block xl:flex">
          <div class="block text-left pl-8 xl:pl-0
                xl:flex xl:flex-wrap xl:content-around
                w-full xl:w-2/4">
            <div v-for="(item,i) in item4" :key="i">
              <span class="blueDot"></span>
              <p class="iTitle">{{item.title}}</p>
              <p class="iDescribe">{{item.describe}}</p>
            </div>
          </div>
          <div class="w-full xl:w-2/4">
            <img class="max-w-full max-h-full mx-auto"
                 src="../assets/home/4-1.png">
          </div>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">产品优势</p>
        <div class="flex justify-around flex-wrap xl:justify-between">
          <item5 class="mb-8 md:mb-16 xl:mb-0"
                 v-for="(item,i) in item5"
                 :key="i" :info="item"></item5>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="mx-auto xl:w-1200">
        <p class="title">应用场景</p>
        <div class="block xl:flex justify-between">

          <div class="w-full xl:w-2/4 hidden xl:block">
            <img class="max-w-full max-h-full mx-auto"
                 src="../assets/home/scene.png">
          </div>

          <div class="right w-full xl:w-2/4">
            <div v-for="(item,i) in item6" :key="i" class="items">
              <img class="w-80px md:w-100px h-80px md:h-100px"
                   :src="require('../assets/home/' + item.img)">
              <span>
                <p class="iTitle">{{item.title}}</p>
                <p class="iDescribe">{{item.describe}}</p>
              </span>
            </div>
          </div>

          <div class="w-full xl:w-2/4 block xl:hidden">
            <img class="max-w-full max-h-full mx-auto"
                 src="../assets/home/scene.png">
          </div>
        </div>
      </div>

    </div>
    <foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import item2 from '@/components/home/item2'
import item3 from '@/components/home/item3'
import item5 from '@/components/home/item5'

import banner from '@/components/websiteHeader'
import foot from '@/components/websiteFooter'

export default {
  name: 'Home',
  components: {
    item2,item3,item5,banner,foot
  },
  data() {
    return {
      cardBack: [
        {carouselOne: require('../assets/banner/首页/首页.png')},
        {carouselOne: require('../assets/banner/首页/产品与服务.jpg')},
        {carouselOne: require('../assets/banner/首页/解决方案.jpg')},
      ],
      item1Info: ['提供高可用数据管理服务','满足多类型可信应用场景','具备区块链可信证实机制'],
      item1: [
        {name: '信任', img: '1-1.png'},
        {name: '数据', img: '1-2.png'},
        {name: '交换', img: '1-3.png'},
        {name: '存证', img: '1-4.png'},
        {name: '效验', img: '1-5.png'},
      ],
      item2: [
        {
          title: '可信服务',
          describe: '通过将各种高可用数据管理技术与业务进行精准整合，为各行各业提供定制化解决方案，实现对原有业务体系优化、整合、加强的可信化管理',
          items: ['数据可视管理','业务结构优化','应用系统集成','技术环境改进'],
          img: '2-1.png',
        },{
          title: '可信产品',
          describe: '基于数据全生命周期，满足多种应用场景的需求提供各类可信产品服务',
          items: ['业务可信','数据可信','可信溯源','应用可信','可信追踪','权限可信','源点可信','存储可信'],
          img: '2-2.png',
        },{
          title: '可信链网',
          describe: '基于分布式区块链技术，提供数据可信链上验证服务，支持复杂链网综合治理',
          items: ['支持多版本链路环境','支持私有化专链部署','支持链数据价值治理','可配置访问权限管理','兼容链路间数据互信'],
          img: '2-3.png',
        }
      ],
      item3: [
        {img: '3-1.png', title: '去中心化/多中心化', describe: '为政务数据共享、防篡改提供广泛的信任基础'},
        {img: '3-2.png', title: '数据完整性保护', describe: '高安全与可靠性，不可伪造，使得数据价值得到保护和认证'},
        {img: '3-3.png', title: '数据可信、可追溯', describe: '数据可信、数据行为可追溯保证数据提供者的权益，并提供数据的不可抵赖性'},
        {img: '3-4.png', title: '智能合约', describe: '有效促进政务业务流程的规范化、智能化与自动化'},
        {img: '3-5.png', title: '数据安全性', describe: '数据可信、数据行为可追溯保证数据提供者的权益，并提供数据的不可抵赖性'},
      ],
      item4: [
        {title: '业务可信', describe: '通过将可信服务、可信产品与业务进行整合，满足业务的可信监控管理'},
        {title: '可信链网', describe: '提供分布式可信链运行环境搭建、链数据管理、链节点管控等服务'},
        {title: '项目定制', describe: '提供应用优化、数据分析、数据可视化等项目管理服务'},
        {title: '专业方案', describe: '提供特定专项的数据治理、业务整合、业务综合监控等服务'},
      ],
      item5: [
        {title: '轻便', describe: '基于分布式链网管理的可信服务，拥有多节点共识化特点，运行及验证环境稳定可靠', img: '5-1.png'},
        {title: '稳定', describe: '基于分布式链网管理的可信服务，拥有多节点共识化特点，运行及验证环境稳定可靠', img: '5-2.png'},
        {title: '兼容', describe: '适用于各种系统环境、存储环境、网络环境、安全环境，兼容主流数据库、各种开发语言，不同版本之间皆可兼容', img: '5-3.png'},
        {title: '灵活', describe: '以业务为核心，而不是以功能为核心，可按业务的需要灵活配置更贴近业务需要的可信解决方案', img: '5-4.png'},
      ],
      item6: [
        {title: '政府', describe: '适用于政府内部管理系统、部门之间系统对接、专项应用系统、业务门户平台、政务一体化集成等提供可信服务', img: '6-1.png'},
        {title: '企业', describe: '可应用于OA、CRM、ERP、SCM等系统，为生产及管理的内网及与外网的安全通讯提供可信管理服务', img: '6-2.png'},
        {title: '用户', describe: '满足个人常见业务可信授权管理的需求，提供多方面的个人信息授权验证可信管理服务', img: '6-3.png'},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  padding: 80px;
}

.item {
  padding: 0 2vw 6vh;
}
.item:nth-child(odd) {
  background: #FBFBFD;
}

.blueDot {
  display: inline-block;
  background: #2555FF;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.item:nth-child(5) {
  .iTitle {
    display: inline-block;
    font-weight: bold;
    font-size: 18px;
  }

  .iDescribe {
    padding-left: 20px;
    color: #555;
    font-size: 16px;
    line-height: 36px;
  }
}

.item:nth-child(7) {
  .items {
    display: flex;
    text-align: left;
    margin: 8% 0;
  }
  .iTitle {
    font-weight: bold;
    color: #333;
    font-size: 18px;
    line-height: 30px;
  }
  .iDescribe {
    color: #666;
    font-size: 16px;
    width: 90%;
  }
}


.fs16 {
  font-size: 16px;
  line-height: 36px;
}

@media screen and ( max-width: 1280px ) {
  .title {
    padding: 30px;
    font-size: 20px;
  }
}

@media screen and ( max-width: 640px ) {
  .fs16 {
    font-size: 14px;
  }

  .item:nth-child(5) {
    .iTitle {
      font-size: 16px;
    }

    .iDescribe {
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .item:nth-child(7) {
    .iTitle {
      font-size: 16px;
    }

    .iDescribe {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
</style>
