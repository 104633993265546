<template>
  <!--  顶部大图  -->
  <!--  <div class="topImg bg-cover bg-no-repeat bg-center">
    <div class=" text-left py-5 md:py-32 2xl:pt-40 2xl:pb-48">
      <div class="header_titleT mt-2 mb-4 ml-8 font-normal text-lg
                  md:text-5xl md:mt-20 md:ml-32 md:mb-8
                  2xl:ml-64 2xl:mt-0 2xl:mb-9">
        数据可信后台管理系统
      </div>
      <div class="header_titleT ml-8 text-xs font-normal w-4/12 mb-3
                  md:text-xl md:ml-32 md:mb-9
                  2xl:ml-64 2xl:mb-12 2xl:text-2xl 2xl:w-3/12">
        为企业提供可信的大规模数据存储以及可控的数据共享环境，促进产业互联网和数字经济领域的协同创新。
      </div>
      <div class="header_button ml-8 rounded-2xl w-14 h-5 leading-5  text-center text-xs cursor-pointer
                  md:w-40 md:h-10 md:leading-10 md:text-lg md:ml-32 2xl:ml-64">
        申请试用
      </div>
    </div>
  </div>-->
  <div class="block">
    <el-carousel class="heightCar"
                 arrow="never"
                 :indicator-position="(cardBack.length == 1) ? 'none' : 'inside'">
      <el-carousel-item v-for="(item,i) in cardBack" :key="i">
          <img :src="item.carouselOne">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "websiteHeader",
  props: {
    cardBack: {
      type: Array,
      default: function () {
        return [
          {
            carouselOne:require('../assets/CarouselOne.png')
          },
        ]
      }
    },
  },
  data(){
    return{
      // cardBack:[
      //   {
      //     carouselOne:require('../assets/CarouselOne.png')
      //   },
      //   {
      //     carouselOne:require('../assets/CarouselOne.png')
      //   },
      //   {
      //     carouselOne:require('../assets/CarouselOne.png')
      //   },
      // ]
    }
  }
}
</script>

<style scoped>
/deep/ .heightCar .el-carousel__container{
  height: 600px;
}
@media screen and (max-width:1600px) {
  /deep/ .heightCar .el-carousel__container{
    height: 500px;
    width: 1600px;
  }
  @media screen and (max-width:1300px) {
    /deep/ .heightCar .el-carousel__container{
      height: 406px;
      width: 1300px;
    }
    @media screen and (max-width:750px){
      /deep/ .heightCar .el-carousel__container{
        height: 200px;
        width: 750px;
      }
      @media screen and (max-width:450px){
        /deep/ .heightCar .el-carousel__container{
          height: 140px;
          width: 450px;
        }
      }
    }
  }

}
/*.el-carousel__container{
  height: 300px;
}*/
/*.topImg{
  background-image: url("../assets/home/banner.png");
}
.header_titleT{
  color: #FFFFFF;
}
.header_button{
  background-color: #FFFFFF;
  color: #2D50E3;
}*/

</style>