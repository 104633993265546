<template>
<div class="item3">
  <img :src="require('../../assets/home/' + info.img)">
  <p class="title">{{ info.title }}</p>
  <p class="describe">{{ info.describe }}</p>
</div>
</template>

<script>
export default {
  name: "item3",
  props: ['info']
}
</script>

<style scoped lang="scss">
.item3 {
  background: rgba(12, 60, 221, 0.04);
  border-radius: 30px;
  padding: 30px 20px;
  box-sizing: border-box;
}
.title {
  font-weight: bold;
  font-size: 18px;
  margin: 25px auto 30px;
  white-space: nowrap;
}
.describe {
  color: #555;
  font-size: 14px;
  line-height: 24px;
}

img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
}

@media screen and ( min-width: 768px ) {
  .item3 {
    width: 230px;
    height: 322px;
  }
}

@media screen and ( max-width: 768px ) {
  .item3 {
    width: 45%;
    padding: 0px 10px 20px 10px;
  }

  .title {
    font-size: 16px;
    margin: 20px auto;
  }

  .describe {
    font-size: 14px;
    line-height: 24px;
  }
}
</style>