<template>
  <div id="app" class="pt-60 md:pt-88 xl:pt-99">
    <menu1/>
    <router-view/>
  </div>
</template>

<script>
import menu1 from '@/components/menu'
export default {
  name: "app",
  components: {
    menu1,
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #2c3e50;
  //width: 100vw;
}
</style>
