import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/styles/globalCSS.css'
import './index.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

//import VueTouch from 'vue-touch'
//Vue.use(VueTouch, {name: 'v-touch'})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
