<template>
  <div>
    <div class="nav hidden xl:flex">

      <div class="cursor-pointer"
           style="display: flex"
           @click="$router.push({ path: '/' })">
        <img src="../../src/assets/logo3.png">
<!--        <img src="../../src/assets/logo2.png">-->
      </div>
      <router-link to="/">首页</router-link>
      <router-link to="/product">产品与服务</router-link>
      <router-link to="/solution">解决方案</router-link>
      <router-link to="/about">关于我们</router-link>
      <!--<div class="btn hidden lg:block" @click="goXinHe">申请试用</div>-->
      <a href="http://www.cndtcloud.com:82" target="_blank" class="btn">可信验证</a>
    </div>

    <div class="foldmenu flex justify-between items-center
                px-4 fixed z-50
                xl:hidden"
          style="">
      <img style="width: 114px;height: 43px;" src="../../src/assets/logo3.png">
      <img @click="showMenu = !showMenu" class="w-6 h-6" src="../../src/assets/menuBtn.png">
    </div>

    <el-drawer
        :visible.sync="showMenu"
        direction="rtl"
        :with-header="false">
      <div class="miniMenu" @click="showMenu = false">
        <router-link to="/">首页</router-link>
        <div class="line"></div>
        <router-link to="/product">产品与服务</router-link>
        <div class="line"></div>
        <router-link to="/solution">解决方案</router-link>
        <div class="line"></div>
        <router-link to="/about">关于我们</router-link>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "menu1",
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    goXinHe() {
      window.open('https://www.itxinhe.com/')
    },
  }
}
</script>

<style scoped lang="scss">
.nav {
  margin: 0 auto;
  height: 99px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 16vw;
  background: white;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 4px 0 #ececec;

  img {
    height: 60px;
  }

  .btn {
    cursor: pointer;
    background: #096DD9;
    color: white;
    border-radius: 50px;
    padding: 9px 20px;
  }

  a {
    display: block;
  }
}

a {
  color: #272B2E;
  text-decoration: none;

  &.router-link-exact-active {
    color: #096DD9;
  }
}

.miniMenu {
  .line {
    border-top: 1px solid #ccc;
  }

  a {
    font-size: 1rem;
    line-height: 3rem;
    width: 100%;
    display: block;

    &.router-link-exact-active {
      //background: #EEF6FF;
    }
  }
}

.foldmenu {
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background: white;
  box-shadow: 0 2px 4px 0 #ececec;
}

@media screen and (max-width: 768px) {
  .miniMenu {
    a {
      font-size: 16px;
      line-height: 4rem;
    }
  }
  .foldmenu {
    height: 60px;
  }
}
</style>